import { Typography, WithStyles, withStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { T } from "react-targem";

import ButtonLink from "@src/components/ButtonLink/ButtonLink";

import { styles } from "./Footer.styles";

export type FooterProps = WithStyles<typeof styles> & {
  className?: string;
};

class Footer extends React.PureComponent<FooterProps> {
  public render() {
    const { classes, className } = this.props;

    return (
      <div className={cn(className, classes.container)}>
        <Typography variant="h4" className={classes.header}>
          <T message="Didn’t find an opening within your area of expertise?" />
        </Typography>
        <Typography variant="body1" className={classes.subHeader}>
          <T message="Let us know what you are looking for and we will help you find the perfect match." />
        </Typography>
        <ButtonLink to="/apply-for-job" variant="contained" color="secondary">
          <T message="Drop a line" />
        </ButtonLink>
      </div>
    );
  }
}

export default withStyles(styles)(Footer);
