import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      scrollBehavior: "smooth",
      marginBottom: theme.spacing(25),
    },
    header: {
      marginTop: theme.spacing(6),
    },
    teamPhotos: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(20),
    },
    footer: {
      marginTop: theme.spacing(20),
    },
  });
