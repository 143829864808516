import { WithStyles, withStyles } from "@material-ui/core";
import cn from "classnames";
import GatsbyImage from "gatsby-image";
import React from "react";

import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { CareersPageProps } from "@src/templates/CareersPage";

import { styles } from "./Mobile.styles";

interface MobileProps
  extends WithStyles<typeof styles>,
    WithPageData<CareersPageProps> {
  className?: string;
}

class Mobile extends React.PureComponent<MobileProps> {
  public render() {
    const { classes, className, pageData } = this.props;
    const {
      teamPhoto1,
      teamPhoto2,
      teamPhoto3,
      teamPhoto4,
      teamPhoto5,
    } = pageData;

    return (
      <div className={cn(classes.container, className)}>
        <GatsbyImage
          fluid={teamPhoto2.childImageSharp.fluid}
          className={classes.photo}
        />
        <div className={classes.photoWrapper}>
          <GatsbyImage
            fluid={teamPhoto1.childImageSharp.fluid}
            className={cn(classes.photo, classes.verticalPhoto)}
          />
          <GatsbyImage
            fluid={teamPhoto5.childImageSharp.fluid}
            className={cn(classes.photo, classes.verticalPhoto)}
          />
        </div>
        <GatsbyImage
          fluid={teamPhoto4.childImageSharp.fluid}
          className={classes.photo}
        />
        <GatsbyImage
          fluid={teamPhoto3.childImageSharp.fluid}
          className={classes.photo}
        />
      </div>
    );
  }
}

export default withPageData(withStyles(styles)(Mobile));
