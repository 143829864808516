import { Typography, WithStyles, withStyles } from "@material-ui/core";
import React from "react";
import { T } from "react-targem";

import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { CareersPageProps } from "@src/templates/CareersPage";
import BlockOfVacancies from "@src/templates/CareersPage/OpenPositions/BlockOfVacancies";
import { VacancyType } from "@src/templates/CareersPage/OpenPositions/BlockOfVacancies/Vacancy";

import { styles } from "./OpenPositions.styles";

interface VacanciesByDepartment {
  [departmentName: string]: VacancyType[];
}

interface OpenPositionsProps
  extends WithStyles<typeof styles>,
    WithPageData<CareersPageProps> {
  openPositionsRef: React.Ref<HTMLDivElement>;
}

class OpenPositions extends React.PureComponent<OpenPositionsProps> {
  public render() {
    const { classes, openPositionsRef } = this.props;
    const vacanciesByDepartment = this.getVacanciesByDepartment();
    const departments = Object.keys(vacanciesByDepartment);

    return (
      <div
        className={classes.container}
        id="openPositions"
        ref={openPositionsRef}>
        <Typography variant="h4" className={classes.header}>
          <T message="Open positions" />
        </Typography>
        {departments.length > 0 ? (
          departments.map((department: string, index) => (
            <BlockOfVacancies
              key={department}
              withDivider={index > 0}
              departmentName={department}
              vacancies={vacanciesByDepartment[department]}
            />
          ))
        ) : (
          <Typography variant="body1" className={classes.noPositions}>
            <T message="There are no current open positions for now." />
          </Typography>
        )}
      </div>
    );
  }

  private getVacanciesByDepartment = (): VacanciesByDepartment => {
    const { nodes } = this.props.pageData.openPositions;

    return nodes.reduce(
      (
        res: VacanciesByDepartment,
        { frontmatter: { departmentName, location, title }, fields: { slug } },
      ) => {
        res[departmentName] = res[departmentName] || [];
        res[departmentName].push({
          location,
          title,
          slug,
        });
        return res;
      },
      {},
    );
  };
}

const OpenPositionsContainer = withPageData(withStyles(styles)(OpenPositions));

export default React.forwardRef((props, ref: React.Ref<HTMLDivElement>) => (
  <OpenPositionsContainer openPositionsRef={ref} {...props} />
));
