import { Hidden } from "@material-ui/core";
import React from "react";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

export type TeamPhotosProps = {
  className?: string;
};

class TeamPhotos extends React.PureComponent<TeamPhotosProps> {
  public render() {
    const { className } = this.props;

    return (
      <>
        <Desktop className={className} />
        <Hidden mdUp>
          <Mobile className={className} />
        </Hidden>
      </>
    );
  }
}

export default TeamPhotos;
