// Pages cannot be exported using `export { default } from "..."`
// shorthand due to Gatsby bug https://github.com/gatsbyjs/gatsby/issues/12384

import CareersPage from "@src/templates/CareersPage";
export default CareersPage;
import { graphql } from "gatsby";

export const pageQuery = graphql`
  query CareersPageQuery {
    teamPhoto1: file(relativePath: { eq: "images/team_photo1.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    teamPhoto2: file(relativePath: { eq: "images/teamPhotos/2.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    teamPhoto3: file(relativePath: { eq: "images/team_photo3.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    teamPhoto4: file(relativePath: { eq: "images/team_photo4.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    teamPhoto5: file(relativePath: { eq: "images/team_photo5.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    openPositions: allMarkdownRemark(
      filter: { fields: { slug: { regex: "/^/open-positions//" } } }
    ) {
      nodes {
        frontmatter {
          departmentName
          location
          title
        }
        fields {
          slug
        }
      }
    }
  }
`;
