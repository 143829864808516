import { WithStyles, withStyles } from "@material-ui/core";
import cn from "classnames";
import GatsbyImage from "gatsby-image";
import React from "react";

import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { CareersPageProps } from "@src/templates/CareersPage";

import { styles } from "./Desktop.styles";

interface DesktopProps
  extends WithStyles<typeof styles>,
    WithPageData<CareersPageProps> {
  className?: string;
}

class Desktop extends React.PureComponent<DesktopProps> {
  public render() {
    const { classes, className, pageData } = this.props;
    const {
      teamPhoto1,
      teamPhoto2,
      teamPhoto3,
      teamPhoto4,
      teamPhoto5,
    } = pageData;

    return (
      <div className={cn(classes.container, className)}>
        <div className={cn(classes.leftColumn, classes.column)}>
          <GatsbyImage
            fluid={teamPhoto1.childImageSharp.fluid}
            className={cn(classes.upperPhoto, classes.photo)}
          />
          <GatsbyImage
            fluid={teamPhoto4.childImageSharp.fluid}
            className={classes.photo}
          />
        </div>
        <div className={cn(classes.rightColumn, classes.column)}>
          <GatsbyImage
            fluid={teamPhoto2.childImageSharp.fluid}
            className={cn(classes.upperPhoto, classes.photo)}
          />
          <div className={classes.photoWrapper}>
            <GatsbyImage
              fluid={teamPhoto5.childImageSharp.fluid}
              className={cn(classes.photo, classes.verticalPhoto)}
            />
            <GatsbyImage
              fluid={teamPhoto3.childImageSharp.fluid}
              className={cn(classes.photo, classes.verticalPhoto)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withPageData(withStyles(styles)(Desktop));
