import React from "react";

import SeoHead from "@src/components/SeoHead";
import IndexLayout from "@src/layouts/IndexLayout";
import { PageWithImagesProps } from "@src/types";
import { t } from "@src/utils/translations";

import CareersTemplate from "./CareersTemplate";

export type CareersPageProps = PageWithImagesProps<
  "teamPhoto1" | "teamPhoto2" | "teamPhoto3" | "teamPhoto4" | "teamPhoto5"
> & {
  data: {
    openPositions: {
      nodes: {
        frontmatter: {
          departmentName: string;
          location: string;
          title: string;
        };
        fields: {
          slug: string;
        };
      }[];
    };
  };
};

class CareersPage extends React.Component<CareersPageProps> {
  public render() {
    return (
      <IndexLayout {...this.props}>
        <SeoHead
          title={t("We are hiring")}
          description={t("Come to Trucknet to be our perfect match")}
          keywords={[t("match"), t("team"), t("hiring"), t("open positions")]}
        />
        <CareersTemplate />
      </IndexLayout>
    );
  }
}

export default CareersPage;
