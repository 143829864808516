import { Divider, Typography, WithStyles, withStyles } from "@material-ui/core";
import React from "react";

import { styles } from "./BlockOfVacancies.styles";
import Vacancy, { VacancyType } from "./Vacancy";

interface OpenPositionsProps extends WithStyles<typeof styles> {
  departmentName: string;
  vacancies: VacancyType[];
  withDivider: boolean;
}

class BlockOfVacancies extends React.PureComponent<OpenPositionsProps> {
  public render() {
    const { classes, withDivider, departmentName, vacancies } = this.props;

    return (
      <React.Fragment>
        {withDivider ? <Divider /> : null}
        <div className={classes.position}>
          <Typography variant="h6" className={classes.groupName}>
            {departmentName}
          </Typography>
          <div className={classes.vacanciesContainer}>
            {vacancies.map((vacancy: VacancyType) => (
              <Vacancy key={vacancy.title} {...vacancy} />
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(BlockOfVacancies);
