import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    photoWrapper: {
      display: "flex",
      flex: "auto",
      margin: theme.spacing(1, -1),
    },
    photo: {
      flex: "auto",
      margin: theme.spacing(1, 0),
    },
    verticalPhoto: {
      margin: theme.spacing(0, 1),
    },
  });
