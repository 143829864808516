import { Button, Typography, WithStyles, withStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { T } from "react-targem";

import { styles } from "./Header.styles";

export type HeaderProps = WithStyles<typeof styles> & {
  className?: string;
  onSeePositionsClick(): void;
};

class Header extends React.PureComponent<HeaderProps> {
  public render() {
    const { classes, onSeePositionsClick, className } = this.props;

    return (
      <div className={cn(className, classes.container)}>
        <Typography variant="h2" className={classes.header}>
          <T message="We are hiring" />
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={onSeePositionsClick}>
          <T message="See open positions" />
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(Header);
