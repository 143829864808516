import { Container, WithStyles, withStyles } from "@material-ui/core";
import React, { RefObject } from "react";

import { styles } from "./CareersTemplate.styles";
import Footer from "./Footer";
import Header from "./Header";
import OpenPositions from "./OpenPositions";
import TeamPhotos from "./TeamPhotos";

export type CareersTemplateProps = WithStyles<typeof styles> & {};

interface State {
  openPositionsRef: RefObject<HTMLDivElement> | null;
}

class CareersTemplate extends React.Component<CareersTemplateProps, State> {
  private openPositionsRef = React.createRef<HTMLDivElement>();

  public render() {
    const { classes } = this.props;

    return (
      <Container className={classes.container}>
        <Header
          onSeePositionsClick={this.handleSeePositionsClick}
          className={classes.header}
        />
        <TeamPhotos className={classes.teamPhotos} />
        <OpenPositions ref={this.openPositionsRef} />
        <Footer className={classes.footer} />
      </Container>
    );
  }

  private handleSeePositionsClick = () => {
    const { current } = this.openPositionsRef;
    if (current) {
      current.scrollIntoView({ behavior: "smooth" });
    }
  };
}

export default withStyles(styles)(CareersTemplate);
