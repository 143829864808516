import { Hidden, Typography, WithStyles, withStyles } from "@material-ui/core";
import { LocationOnOutlined } from "@material-ui/icons";
import React from "react";
import { T } from "react-targem";

import ButtonLink from "@src/components/ButtonLink/ButtonLink";
import LocalizedLink from "@src/components/LocalizedLink";

import { styles } from "./Vacancy.styles";

export interface VacancyType {
  title: string;
  location: string;
  slug: string;
}

interface VacancyProps extends WithStyles<typeof styles>, VacancyType {}

class Vacancy extends React.PureComponent<VacancyProps> {
  public render() {
    const { classes, title, location, slug } = this.props;

    return (
      <div key={title} className={classes.vacancy}>
        <LocalizedLink className={classes.link} to={slug}>
          <Typography variant="body1" className={classes.vacancyTitle}>
            {title}
          </Typography>
          <Typography variant="body1" className={classes.locationText}>
            <LocationOnOutlined className={classes.locationIcon} />
            {location}
          </Typography>
        </LocalizedLink>
        <Hidden xsDown>
          <ButtonLink
            to={slug}
            color="secondary"
            className={classes.applyButton}>
            <T message="Apply" />
          </ButtonLink>
        </Hidden>
      </div>
    );
  }
}

export default withStyles(styles)(Vacancy);
