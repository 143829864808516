import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: "1040px",
      width: "100%",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    column: {
      flex: "auto",
      display: "flex",
      flexDirection: "column",
    },
    leftColumn: {
      width: "400px",
      marginRight: theme.spacing(1.5),
    },
    rightColumn: {
      width: "616px",
      marginLeft: theme.spacing(1.5),
    },
    photoWrapper: {
      display: "flex",
      flex: "auto",
      margin: theme.spacing(0, -1.5),
    },
    photo: {
      flex: "auto",
    },
    upperPhoto: {
      marginBottom: theme.spacing(3),
    },
    verticalPhoto: {
      margin: theme.spacing(0, 1.5),
    },
  });
