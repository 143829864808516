import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    locationText: {
      color: theme.palette.text.secondary,
      display: "flex",
      alignItems: "center",
    },
    locationIcon: {
      fontSize: "16px",
      color: theme.palette.secondary.main,
      marginRight: theme.spacing(1),
    },
    vacancy: {
      display: "flex",
      paddingBottom: theme.spacing(5),
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        paddingBottom: theme.spacing(3),
      },
    },
    vacancyTitle: {
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        paddingBottom: theme.spacing(0.5),
      },
    },
    link: {
      textDecoration: "none",
    },
    applyButton: {
      height: "36px",
      minWidth: "60px",
    },
  });
