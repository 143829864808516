import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    position: {
      display: "flex",
      paddingTop: theme.spacing(5),
      [theme.breakpoints.down("xs")]: {
        paddingTop: theme.spacing(3),
        display: "block",
      },
    },
    groupName: {
      width: "50%",
      [theme.breakpoints.down("xs")]: {
        paddingBottom: theme.spacing(3),
      },
    },
    vacanciesContainer: {
      width: "50%",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  });
